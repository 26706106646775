import React from "react";
import pigeon_meme from "../../assets/pigeon_meme.webp";

const d = new Date();
let year = d.getFullYear();

const Footer = () => {
  return (
    <div className="bg-gray-800 p-12 text-gray-50">
      <div className="flex flex-col space-y-2 items-center justify-center">
        <p className="text-sm md:text-base">
          Overfitting object detection models be like:
        </p>
        <img
          src={pigeon_meme}
          alt="pigeon meme"
          className="h-[220px] lg:h-[300px]"
        />
      </div>
      <div className="text-center mt-12 text-xs">
        <div className=" space-x-4">
          <ul className="text-base py-6 flex space-x-4 justify-center items-center underline underline-offset-4">
            <a href="https://linkedin.com/in/siddheshkulthe">
              <li>LinkedIn</li>
            </a>
            <a href="https://github.com/siddheshtv">
              <li>GitHub</li>
            </a>
            <a href="https://www.knmfirm.com/?utm_source=siddhesh_website">
              <li>K&M</li>
            </a>
          </ul>
        </div>
        <p>&copy; Copyright {year}, Siddhesh Kulthe. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
