import React from "react";

const Research = () => {
  return (
    <div className="pt-12 pb-6 flex flex-col space-y-8" id="research">
      <div className="flex flex-col space-y-5 justify-center items-start">
        <h1 className="text-2xl font-medium">Research Interests</h1>
        <hr className="w-4/5 bg-gray-300 h-1" />
      </div>
      <div>
        <ul className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
          <li className="px-4 py-2 rounded-sm bg-gray-100 border-2 border-gray-400 text-xs">
            Deep Learning
          </li>
          <li className="px-4 py-2 rounded-sm bg-gray-100 border-2 border-gray-400 text-xs">
            Neural Networks
          </li>
          <li className="px-4 py-2 rounded-sm bg-gray-100 border-2 border-gray-400 text-xs">
            Reinforcement Learning
          </li>
          <li className="px-4 py-2 rounded-sm bg-gray-100 border-2 border-gray-400 text-xs">
            Optimizers
          </li>
          <li className="px-4 py-2 rounded-sm bg-gray-100 border-2 border-gray-400 text-xs">
            Transformers
          </li>
          <li className="px-4 py-2 rounded-sm bg-gray-100 border-2 border-gray-400 text-xs">
            Computer Vision
          </li>
          <li className="px-4 py-2 rounded-sm bg-gray-100 border-2 border-gray-400 text-xs">
            Quantum Mechanics
          </li>
          <li className="px-4 py-2 rounded-sm bg-gray-100 border-2 border-gray-400 text-xs">
            Bohmian Mechanics
          </li>
          <li className="px-4 py-2 rounded-sm bg-gray-100 border-2 border-gray-400 text-xs">
            Eigenstates
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Research;
