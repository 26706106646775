import React, { useEffect } from "react";

const Portfolio = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//cdn.jsdelivr.net/github-cards/latest/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="py-6 flex flex-col space-y-8" id="portfolio">
      <div className="flex flex-col space-y-5 justify-center items-start">
        <h1 className="text-2xl font-medium">GitHub Portfolio</h1>
        <hr className="w-4/5 bg-gray-300 h-1" />
      </div>
      <div className="flex flex-col space-y-5">
        <h1>
          <a
            href="https://github.com/siddheshtv"
            className="underline underline-offset-4"
          >
            @siddheshtv &rarr;
          </a>
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <img
            src="http://github-profile-summary-cards.vercel.app/api/cards/repos-per-language?username=siddheshtv&theme=default"
            alt="Siddhesh Kulthe Github Most Used Languages"
            className="w-full"
          />
          <img
            src="http://github-profile-summary-cards.vercel.app/api/cards/stats?username=siddheshtv&theme=default"
            alt="Siddhesh Kulthe Github Stats"
            className="w-full"
          />
        </div>
        <div>
          <img
            src="http://github-profile-summary-cards.vercel.app/api/cards/profile-details?username=siddheshtv&theme=default"
            alt="Siddhesh Kulthe Github Contributions Summary"
            className="lg:w-[4/5]"
          />
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
