import React from "react";

const work = [
  {
    title: "ML Developer Intern",
    company: "Simusoft Technologies, Pvt Ltd.",
    location: "Pune, MH",
    duration: "Nov 2023 - Jan 2024",
    description: [
      "Developed and fine-tuned a deep-learning model for leopard detection in rural areas. Implemented YOLOv8 and worked on reducing false negatives and the overall ROC AUC metric. Collaborated with other interns and developed an effective system to reduce the image processing timings and response latency from 400 ms to 172 ms. Deployed the model using AWS Lambda, S3 Bucket, and other serverless functionality. Also made use of Raspberry Pie, a network router, and a GSM module for communication between the serverless hosted model and the cameras in specific regions.",
    ],
  },

  {
    title: "Machine Learning Engineer (Freelance)",
    company: "Upwork",
    location: "Remote",
    duration: "Jan 2023 - Oct 2023",
    description: [
      "Developed and deployed a machine learning model for a startup company in the finance industry, resulting in a 8% reduction in fraudulent transactions. Collaborated with a team of AI Engineers to develop a grammar correction system using Pattern Recognition and Natural Language Processing. Improved the training time by implementing out-of-core learning and reducing the dimensionality of the dataset. Optimized a machine learning pipeline for a retail supermarket chain and worked on the recommendation system to offer personalised suggestions based on previous purchases.",
    ],
  },
  {
    title: "Web Developer",
    company: "Codemugg, Inc.",
    location: "Nashik, MH",
    duration: "Dec 2020 - Mar 2022",
    description: [
      "Developed a new platform for the company, resulting in a 23% decrease in web-page loading times, and increased customer retention by 5%. Collaborated with various teams to define and implement a technology roadmap for the learning management system, resulting in increasing efficiency of the development team and the project success rate. Implemented security and data protection solutions, resulting in 99.8% uptime on bare metal servers for 9 months. Implemented cluster orchestration using Kubernetes and also used Docker to improve the efficiency of the whole development environment.",
    ],
  },
];

const Work = () => {
  return (
    <div className="py-6 flex flex-col space-y-8" id="work">
      <div>
        <div className="flex flex-col space-y-5 justify-center items-start">
          <h1 className="text-2xl font-medium">Work Experience</h1>
          <hr className="w-4/5 bg-gray-300 h-1" />
        </div>
        <div className="flex flex-col pt-5">
          <ul className="flex flex-col divide-y-2">
            {work.map((workstuff) => (
              <li className="py-5 px-4">
                <div className="flex flex-col space-y-4">
                  <div className="grid grid-cols-1 lg:grid-cols-2 items-center space-y-4 lg:space-y-0">
                    <div>
                      <h1 className="text-xl font-medium">{workstuff.title}</h1>
                      <p>{workstuff.company}</p>
                    </div>
                    <div className="text-sm text-left lg:text-right font-medium">
                      <p>{workstuff.duration}</p>
                      <p>{workstuff.location}</p>
                    </div>
                  </div>

                  {workstuff.description.map((desc, index) => (
                    <p
                      key={index}
                      className="text-justify hidden md:block lg:text-base"
                    >
                      {desc}
                    </p>
                  ))}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Work;
