import React from "react";
import siddhesh from "../../assets/siddhesh-kulthe.jpg";

const Hero = () => {
  return (
    <div className="py-8 px-4">
      <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-8 xl:gap-4 items-start space-y-12 lg:space-y-0">
        <div className="">
          <img src={siddhesh} alt="Siddhesh Kulthe" />
        </div>
        <div className="flex flex-col space-y-4 text-justify">
          <p>
            He is an undergraduate student at{" "}
            <strong>AISSMS Institute of Information Technology</strong>,
            pursuing his{" "}
            <strong>
              Bachelor of Engineering in Artificial Intelligence and Data
              Science
            </strong>
            . Siddhesh Kulthe is a talented individual specializing in{" "}
            <strong>Neural Networks and Deep Learning</strong>. He is also an{" "}
            <strong>aspiring PhD candidate</strong>. Most of his time during his
            sophomore and junior years has been spent learning and applying
            machine-learning concepts in daily life and solving real-world
            problems. Some of these applications include a resume ranking system
            developed to reduce the time and effort required to shortlist
            candidates, an early customer dissatisfaction detection system for
            websites, and an automated support bot that can reduce support rep
            costs for businesses.
          </p>
          <p>
            Siddhesh is also known to{" "}
            <strong>
              help various businesses solve their pinpointed problems through
              consultations or by developing customized solutions
            </strong>
            . He loves to delve into discussions about mathematics and
            artificial intelligence. Currently, he assists businesses in solving
            problems and making informed decisions by leveraging the data they
            generate. Apart from pioneering AI solutions, Siddhesh brings over{" "}
            <strong>
              five years of extensive expertise in full-stack development
            </strong>
            . His skill set spans a spectrum of relevant technologies, including
            React, AWS, Docker, Kubernetes, MongoDB, Django and FastAPI.
          </p>
        </div>
      </div>
      <div className="pt-20 text-center">
        <p className="text-xl italic text-gray-500">
          24/7 available for research projects & for contributing to society.
        </p>
      </div>
    </div>
  );
};

export default Hero;
