import React from "react";
import Navbar from "../HomeComp/Navbar";
import Hero from "../HomeComp/Hero";
import Research from "../HomeComp/Research";
import Work from "../HomeComp/Work";
import Portfolio from "../HomeComp/Portfolio";
import Contact from "../HomeComp/Contact";
import Footer from "../HomeComp/Footer";

const Home = () => {
  return (
    <div className="bg-[#fafafa]">
      <Navbar />
      <div className="px-5 pt-12 flex flex-col space-y-5 justify-center items-center">
        <div>
          <span className="text-gray-500">Future Dr.</span>
          <h1 className="text-4xl font-medium text-center">Siddhesh Kulthe</h1>
        </div>

        <hr className="w-4/5 bg-gray-300 h-0.5" />
      </div>
      <div className="px-4 lg:px-64">
        <Hero />
        <Research />
        <Work />
        <Portfolio />
        <Contact />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
