import React from "react";

const Contact = () => {
  return (
    <div className="py-6 flex flex-col space-y-8" id="contact">
      <div className="flex flex-col space-y-5 justify-center items-start">
        <h1 className="text-2xl font-medium">Contact</h1>
        <hr className="w-4/5 bg-gray-300 h-1" />
      </div>
      <div className="flex flex-col space-y-2">
        <h1>
          <strong>Email</strong>
          <br /> siddhesh [at] knmfirm [dot] com
          <br /> siddhesh [dot] kulthe [at] aissmsioit [dot] org
        </h1>
        <h1>
          <strong>Address</strong>
          <br /> Sahawas Apartment, Opp. Snehaprachit Apartment. <br />
          Somwar Peth, Pune <br />
          MH, IN, 411011
        </h1>
      </div>
      <div className="bg-gray-700 text-gray-100 p-5">
        <code className=" ">
          $ pip install siddhesh
          <br />
          $ python
          <br />
          <br />
          <span className="text-gray-400"> # this works too</span>
          <br />
          &gt;&gt;&gt; import siddhesh as sk
          <br />
          &gt;&gt;&gt; sk.start()
        </code>
      </div>
    </div>
  );
};

export default Contact;
