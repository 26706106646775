import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const navItems = [
    { id: 1, text: "Research", link: "#research" },
    { id: 2, text: "Work Experience", link: "#work" },
    { id: 3, text: "Portfolio", link: "#portfolio" },
    { id: 4, text: "Contact", link: "#contact" },
  ];
  return (
    <div className="bg-gray-900 flex justify-end items-center p-6 lg:p-5 text-gray-50 fixed top-0 right-0 z-10">
      {/* Desktop Navigation */}
      <ul className="hidden md:flex md:space-x-9 md:px-16">
        {navItems.map((item) => (
          <a href={item.link}>
            <li
              key={item.id}
              className="cursor-pointer duration-300 hover:text-gray-300"
            >
              {item.text}
            </li>
          </a>
        ))}
      </ul>

      {/* Mobile Navigation Icon */}
      <div onClick={handleNav} className="block md:hidden">
        {nav ? <AiOutlineClose size={24} /> : <AiOutlineMenu size={24} />}
      </div>

      {/* Mobile Navigation Menu */}
      <ul
        className={
          nav
            ? "fixed md:hidden left-0 top-0 w-[60%] h-full bg-gray-900 ease-in-out duration-500"
            : "ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 left-[-100%]"
        }
      >
        {navItems.map((item) => (
          <a href={item.link}>
            <li
              key={item.id}
              className="p-6 border-b rounded-lg duration-300 hover:text-gray-300 cursor-pointer border-gray-600"
            >
              {item.text}
            </li>
          </a>
        ))}
      </ul>
    </div>
  );
};

export default Navbar;
